import React from 'react'
import $ from 'jquery';

export class TitleIndex extends React.Component{
  constructor(props) {
        super(props);
        this.state = {
            row : false
        }
    }

 componentDidMount() {
      $(window).bind('load resize', () => {
         if ($(document).width() < 769) {
            this.setState({row: true})
         }else{
            this.setState({row: false})
         }
      });
  }

render() {
const { children } = this.props
if(!this.state.row){
 return(
        <React.Fragment>
          <h5>{children}</h5>
        </React.Fragment>
    )}else{
        return null
    }
  }

}

