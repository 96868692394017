import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { authenticationService } from '../_services';
import { Notif } from '../_components';

export const PrivateRoute = ({ component: Component, idrole, parentCallback, ...rest}) => (
    <Route {...rest} render={props => {
        authenticationService.setExpire()
        const currentUser = authenticationService.currentUserValue;
        const ability = authenticationService.ability;
  
        if (!currentUser || !ability) {
            // not logged in so redirect to login page with the return url
            authenticationService.logout()
            // props.history.push('/login');
            return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        }else if (idrole && !ability.find(e=> e === idrole)){
            Notif('Opsss', 'you not have auth to access ____','toast-top-right','4')
            authenticationService.logout()
            return  <Redirect to={{ pathname: '/', state: { from: props.location } }} />
           
        }

        return <Component parentCallback={parentCallback} {...props} />
    }} />
)

