import { Suspense } from 'react';
import React from 'react';
 

const WaitingComponent=(Component) => {
  return (props) => (
    <Suspense fallback={<Loading/>}>
      <Component {...props}/>
    </Suspense>
  );
};

const Loading= () => (
  <div className="flex-center">
    <div className="lds-roller blue">
      <div/><div/><div/><div/><div/><div/><div/><div/></div>
  </div>
);

const Loading2= () => (
  <div style={{marginLeft: 'auto', marginRight: 'auto'}}>
    <div className="lds-roller blue">
      <div/><div/><div/><div/><div/><div/><div/><div/></div>
  </div>
);

export {WaitingComponent, Loading, Loading2}