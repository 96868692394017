import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import { authHeader } from '../../_helpers'
import axios from 'axios';
import Spacer from 'react-add-space';


const { REACT_APP_API_URL :  API_URL} =process.env

class AkunModalRedux extends React.Component {
  componentDidMount(){
       $('#accounts').DataTable( {
        ordering: true,
        searching: true,
        processing: true,
        serverSide:true,
        bFilter: true,
        bInfo:true,
        bLengthChange: true,
        bPaginate:true,
        ordering: true,
        scrollCollapse: true,
        scrollY: true,
        scrollX: true,
        "ajax": {
          "url": `${API_URL}/api/accounts${this.props.modal.query ? this.props.modal.query : ''}`,
          "dataSrc" : 'data',
          "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',authHeader())
            }
          },
        columnDefs : [
            {
                'targets': [0],
                createdCell : (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<React.Fragment>{rowData[7] ? <b>{rowData[0]}</b> : rowData[0]}</React.Fragment>,td);
                }
            },{
                'targets': [1],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><Spacer amount={rowData[8]*2} />{rowData[7] ? <b>{rowData[1]}</b> : rowData[1]}</React.Fragment>,td);
                }
            },{
                'targets': [3],
                createdCell : (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<React.Fragment>
                            <div align='center'>
                            {this.props.modal.is_open ?
                              
                              <button className="btn btn-primary btn-sm" onClick={() => {this.Pilih(rowData[6],`${rowData[0]} - ${rowData[1]}`)}}>Pilih</button>

                              : !rowData[7] && <button className="btn btn-primary btn-sm" onClick={() => {this.Pilih(rowData[6],`${rowData[0]} - ${rowData[1]}`)}}>Pilih</button>
                              
                             }
                            </div>
                            </React.Fragment>,td);
                }
            }
            
        ]
     } );
    }

   Pilih = (id,name)=>{
     const { Field1, Field2, onClick, onChange }= this.props.modal
     onClick(Field1, name)
     onClick(Field2, id)
     onChange && onChange(id,name)
     this.onClose()
   } 
  
  onClose = ()=>{
    this.props.Modal(null)
  }

  render() {
    console.log(!this.props.is_open,'cek')
    const backdropStyle = {
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };

   
    return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >
         <Modal.Header>
        <Modal.Title>Master Akun</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                <table className="table table-striped" style={{whiteSpace: 'nowrap', width: '100%'}} id="accounts">
                  <thead>
                      <tr>
                          <th>Kode</th>
                          <th>Nama</th>
                          <th>Tipe Akun</th>
                          <th></th>
                      </tr> 
                  </thead>
                </table>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={this.onClose}>Close</Button>
        </Modal.Footer>
        </Modal.Dialog>
      </div>
      </div>
    );
  }
}


export { AkunModalRedux}




