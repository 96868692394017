import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import { authHeader } from '../../_helpers'
import axios from 'axios';
import Spacer from 'react-add-space';

const { REACT_APP_API_URL :  API_URL} =process.env

class ItemsModal extends React.Component {
  componentDidMount(){
       $('#items').DataTable( {
        ordering: true,
        searching: true,
        processing: true,
        serverSide:true,
        bFilter: true,
        bInfo:true,
        bLengthChange: true,
        bPaginate:true,
        ordering: true,
        scrollCollapse: true,
        scrollY: true,
        scrollX: true,
        "ajax": {
          "url": `${API_URL}/api/items_modal${this.props.query ? this.props.query : ''}`,
          "dataSrc" : 'data',
          "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',authHeader())
            }
          },
        columnDefs : [
            {
                'targets': [0],
                createdCell : (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<React.Fragment><button className="btn btn-primary btn-sm" 
                      onClick={() => {
                        this.Pilih(rowData[0],`${rowData[1]} - ${rowData[2]}`, rowData[8])
                      }}>Pilih</button>
                      </React.Fragment>,td);
                }
            },{
                'targets': [8],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><b>{new Intl.NumberFormat( 'ID' ).format(rowData[8])}</b></React.Fragment>,td);
                }
            }
            
        ]
     } );
    }

   Pilih(id,nm,price){
     const { name, onClick, onClose, onChange }= this.props
     onClick(name[0], nm)
     onClick(name[1], id)
     onClick(name[2], price)
     onChange({target : {name : name[2], value: String(price)}})
     onClose()
   } 

  render() {
    // Render nothing if the "show" prop is false
    if(!this.props.show) {
      return null;
    }

    const {onClose, top, bottom}= this.props
    const backdropStyle = {
    
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };

   
    return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >
         <Modal.Header>
        <Modal.Title>Cari Items</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                <table className="table table-striped" style={{whiteSpace: 'nowrap', width: '100%'}} id="items">
                  <thead>
                      <tr>
                          <th></th>
                          <th>Kode</th>
                          <th>Nama</th>
                          <th>Type</th>
                          <th>Satuan</th>
                          <th>Part Number</th>
                          <th>Merek</th>
                          <th>Stok Akhir</th>
                          <th>Harga</th>
                          <th>Note</th>
                      </tr> 
                  </thead>
                </table>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={onClose}>Close</Button>
        </Modal.Footer>
        </Modal.Dialog>
      </div>
      </div>
    );
  }
}


export  { ItemsModal };




