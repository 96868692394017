import * as React from 'react'
 
import ReactMoneyComponent from 'react-currency-input-mask'
import 'react-currency-input-mask/src/assets/style.css'
class IndexComponent extends React.Component {
   constructor(props:any) {
       super(props);
       this.state = {
         price: 2.2,
         config:  {
           prefix: '',
           suffix: '',
           precision: 2,
           includeThousandsSeparator: true,
           thousandsSeparatorSymbol: ',',
           decimalSeparator: '.',
         }
       }
     }
/*
  * Name: changePrice
  * Description: This function is called when user change input value
  * Parameters: price(changed Price)
  * author: Nick Dam
  * */
  changePrice(price:number){
      this.setState({price: price})
  }
  render () {
    return (
        <div>
          <p>{this.state.price}</p>
          <ReactMoneyComponent 
              value={this.state.price} 
              config={this.state.config}
              className='form-control' 
              onChange={(e:any) => this.changePrice(e)}>
          </ReactMoneyComponent>
        </div>
    )
  }
}

export { IndexComponent }