import React from 'react'
import { ErrorMessage } from 'formik';
import AsyncSelect from "react-select/async";
import axios from 'axios';
import { axiosHeader } from '../_helpers'
import { Notif } from '../_components';

const { REACT_APP_API_URL :  API_URL} =process.env

export class SelectUnit extends React.Component{
	constructor(props){
    super(props)
    this.state={
    		options : 0,
    		axiosHeader : axiosHeader(),
    		units : null,
    		id_item : null,
    		id_item_package : null,
    		is_load : false
    	}
	}


	selectDef=(id_item,id_item_package) =>{
		 this.setState({is_load : true})
		 axios.all([
	       axios.get(`${API_URL}/api/multi_units?id_item=${id_item}&id_item_package=${id_item_package}`, this.state.axiosHeader),
	     ])
	     .then(axios.spread((units) => {
	        this.setState(
	            {
	            	units: units.data,
	            	id_item,
	            	id_item_package,
	            	is_load : false
	            }
	          )
	     }))
	     .catch(({response : { request : { response : error }} }) => {
	        Notif('Opssss', error,'toast-bottom-right','4')
	      });
	}

    loadOptions = (inputValue)=> {
	    return new Promise(resolve => {
	    	 const reg = new RegExp(inputValue, "i");
	    	 if(this.state.units){
	    	 	 const filtered = this.state.units.filter(i => reg.test(i.label));
	    	 	 resolve(filtered.slice(0, this.props.view));
	    	 }else{
	    	 	 resolve([])
	    	 }
	    	 
	    })
	  };


render() {
  const {errors, touched, name, select_ref, handleSelect, view, options, defaultValue, setValue, id_item, id_item_package}=this.props

 if(id_item && !this.state.is_load && id_item!==this.state.id_item){
 	this.selectDef(id_item,id_item_package)
 }else if(id_item_package && !this.state.is_load && id_item_package!==this.state.id_item_package){
 	this.selectDef(id_item,id_item_package)
 }
 

 return(
        <div>
              <AsyncSelect
		       	 {...{
		       	 	ref : (ref) => {
		       	 		select_ref && select_ref(ref)
		       	 	},
		       	 	...(setValue && {setValue}),
		       	 	name,
		       	 	defaultOptions : this.state.units ? this.state.units.slice(0, view) : [],
		       	 	className : `basic-multi-select ${errors && touched ? ' is-invalid' : '' }`,
		       	 	classNamePrefix : 'select',
		       	 	loadOptions : (inputValue) => this.loadOptions(inputValue),
		       	 	defaultValue,
		       	 	onChange: (selectChoice, {name}) =>{
		       	 		handleSelect(selectChoice, {name})
		       	 	}
		       	 }}
		        />
              <ErrorMessage name={name} component="div" style={{color:'red'}} className="invalid-feedback" />
        </div>
    )
  }
}
