import React from 'react'
import { Field, ErrorMessage } from 'formik';

export class TextFieldG extends React.Component{

render() {
  const {errors, touched, name, type, onClick, disabled_onClick, placeholder}=this.props
 return(
        <div>
        	<div className="input-group">
              <Field name={name} type={type} disabled={true} placeholder={placeholder ? placeholder : ''} className={'form-control' + (errors && touched ? ' is-invalid' : '')} />
		        <span  className="input-group-addon"  
		        	  onClick={ !disabled_onClick ? onClick : null}>
		              <i className="fa fa-search"></i>
		        </span>
		        <ErrorMessage name={name} component="div" style={{color:'red'}} className="invalid-feedback" />
		      </div>

        </div>
    )
  }
}
