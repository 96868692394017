import React from 'react'
import { Field, ErrorMessage } from 'formik';

export class TextField extends React.Component{

render() {
  let {errors, touched, name, type, className = `form-control`, onChange, placeholder, onBlur= () => {}, disabled}=this.props
  
  if(errors && touched){
    className=`${className} is-invalid`
  }
 return(
        <React.Fragment>
                
            <Field
                  {...{ 
                  name, 
                  type,
                   ... (onChange && { onChange }),
                   ... (onBlur && { onBlur }),
                  disabled,
                   ... (placeholder && { placeholder }),
                   ... (className &&  { className })
                }}
            />

              <ErrorMessage 
                  name={name} 
                  component="div" style={{color:'red'}} 
                  className="invalid-feedback" />
        </React.Fragment>
    )
  }
}


// {onBlur &&
//             <Field 
//                   name={name} 
//                   type={type}
//                   onBlur={onBlur}
//                   placeholder={placeholder? placeholder : ''}
//                   className={className ? className : 'form-control' + (errors && touched ? ' is-invalid' : '')} />

//             }

//             {!onChange && !onBlur &&
//             <Field 
//                   name={name} 
//                   type={type} 
//                   placeholder={placeholder? placeholder : ''}
//                   className={className ? className : 'form-control' + (errors && touched ? ' is-invalid' : '')} />
//             }