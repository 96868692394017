import React, { Component } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { ErrorMessage } from 'formik';
import { axiosHeader } from '../_helpers'
import { Notif } from '../_components';
import axios from 'axios';

const { REACT_APP_API_URL :  API_URL} =process.env

class SelectCreate extends Component<*, State> {
 constructor(props){
    super(props)
    this.state={
        axiosHeader : axiosHeader(),
      }
  }


  loadOptions = (inputValue)=> {
    return new Promise(resolve => {

      if(inputValue.length > 2){
         axios.all([
         axios.get(`${API_URL}${this.props.url}?name=${inputValue}&code=${inputValue}`, this.state.axiosHeader)
       ])
       .then(axios.spread((params) => {
          resolve(params.data.slice(0, this.props.view))
       }))
       .catch(({response : { request : { response : error }} }) => {
              Notif('Opssss', error,'toast-bottom-right','4')
              resolve(null)
         });

          }else{
            resolve(this.props.options)
          }

    })
  };




  render() {
    const {errors, touched, name, select_ref, handleSelect, view, options, defaultValue, disabled}=this.props
    return (
      <React.Fragment>
      <AsyncCreatableSelect
        ref={ref => {
              if(select_ref){
                  select_ref(ref) 
              }
            }}
        name={name}
        isDisabled={disabled ? disabled : false}
        defaultOptions={options.slice(0, view)}
        loadOptions={inputValue => this.loadOptions(inputValue)}
        className={'basic-multi-select' + (errors && touched ? ' is-invalid' : '')} 
        classNamePrefix="select"
        defaultValue={defaultValue}
        onChange={(selectChoice, {name}) =>{
          handleSelect(selectChoice, {name})
        }}
      />
      <ErrorMessage name={name} component="div" style={{color:'red'}} className="invalid-feedback" />
      </React.Fragment>
    );
  }
}

export {SelectCreate}