import React, { Component } from 'react';
import { authenticationService } from '../_services';

class Roles extends React.Component {
   constructor(props) {
        super(props);
        this.state={
          list_roles : [],
          n_path : ''
        }
     
    }

   componentDidMount= () =>{
     const roles=[]
     this.props.children && this.props.children.map(({props})=>{
        roles.push({id: props.idrole, path : props.path, exact : true})
     })

     const path= roles.find(e=> e.id === authenticationService.ability[0]) 
     const n_path=path ? path : roles.find(e=> e.id === authenticationService.ability[0] + 1) 
     this.setState({list_roles: roles, n_path: n_path.path})
   }



   render() {
	    return (
	        <React.Fragment>
	      	{this.props.children}
          </React.Fragment>
	    );

  }
}


export default Roles

