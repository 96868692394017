import { Component } from 'react';
import Pace from 'pace-js';

export class Progress extends Component {
  componentDidMount() {
    Pace.start();
  }
  render() {
    return (null);
  }
}
