import React from 'react'
import { ErrorMessage } from 'formik';
import AsyncSelect from "react-select/async";


export class Select2 extends React.Component{
	constructor(props){
    super(props)
    this.state={
    		options : 0
    	}
	}

    loadOptions = (inputValue)=> {
	    return new Promise(resolve => {
	    	 const reg = new RegExp(inputValue, "i");
	    	 const filtered = this.props.options.filter(i => reg.test(i.label));
	    	 resolve(filtered.slice(0, this.props.view)); 
	    })
	  };


render() {
  const {errors, touched, name, select_ref, handleSelect, view, options, defaultValue, setValue, isMulti, disabled}=this.props
 return(
        <div>
              <AsyncSelect
		       	 {...{
		       	 	...(select_ref && { ref : select_ref}),
		       	 	...(setValue && {setValue}),
		       	 	...(isMulti && {isMulti}),
		       	 	name,
		       	 	isDisabled : disabled ? true : false,
		       	 	defaultOptions : options.slice(0, view),
		       	 	className : `basic-multi-select ${errors && touched ? ' is-invalid' : '' }`,
		       	 	classNamePrefix : 'select',
		       	 	loadOptions : (inputValue) => this.loadOptions(inputValue),
		       	 	defaultValue,
		       	 	onChange: (selectChoice, {name}) =>{handleSelect(selectChoice, {name})}
		       	 }}
		        />
              <ErrorMessage name={name} component="div" style={{color:'red'}} className="invalid-feedback" />
        </div>
    )
  }
}

 // <AsyncSelect
	// 	       	  ref={ref => {
	// 	       	  	if(select_ref){
	// 	       	  	  select_ref=ref	
	// 	       	  	}
	// 	          }}
	// 	       	  name={name}
	// 	          defaultOptions={options.slice(0, view)}
	// 	          className={'basic-multi-select' + (errors && touched ? ' is-invalid' : '')} 
	// 	          classNamePrefix="select"
	// 	          loadOptions={inputValue => this.loadOptions(inputValue)}
	// 	          defaultValue={defaultValue}
	// 		      onChange={(selectChoice, {name}) =>{
	// 		      	handleSelect(selectChoice, {name})
	// 		      }}
	// 	        />