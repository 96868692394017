import React from 'react'

export class RowInput extends React.Component{

render() {
const { children, label, size} = this.props
 return(
          <div className="form-group row">
              <label className="col-lg-3 col-form-label">{label}</label>
              <div className={size ? `col-lg-${size}` : `col-lg-9`}>
                  {children}
              </div>
          </div>
    )
  }

}

