import { BehaviorSubject } from 'rxjs';
import { handleResponse } from '../_helpers';
import { Notif } from '../_components';

const { REACT_APP_API_URL :  API_URL} =process.env
const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('currentUser')));
const ability = new BehaviorSubject(JSON.parse(localStorage.getItem('ability')));

export const authenticationService = {
    login,
    logout,
    setExpire,
    ability : ability.asObservable(),
    get ability () { return ability.value },
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue () { return currentUserSubject.value }
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password})
    };

    return fetch(`${API_URL}/api/authenticate`, requestOptions)
        .then(handleResponse)
        .then(user => {
                const {...users}=user[0].cekAuth
                localStorage.setItem('currentUser', JSON.stringify({...users}));
                localStorage.setItem('ability', JSON.stringify(user[0].cek));
                currentUserSubject.next({...users});
                ability.next(user[0].cek);
            return {...users, cek: user[0].cek};
        });


}

function logout() {
    // remove user from local storage to log user out
    localStorage.clear()
    localStorage.removeItem('currentUser')
    localStorage.removeItem('ability')
    currentUserSubject.next(null);
    ability.next(null);
}

function setExpire(){
    let hours = 1
    let saved = localStorage.getItem('your-time')
    if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
      localStorage.clear()
      currentUserSubject.next(null);
      ability.next(null);
      Notif('Opsss', 'Session timeout _____','toast-top-right','4')
    }else{
      localStorage.setItem('your-time', new Date().getTime())
    }

}
