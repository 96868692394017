import { authenticationService } from '../_services';

export { authHeader, axiosHeader }

	 function authHeader() {
	    // return authorization header with jwt token
	    const currentUser = authenticationService.currentUserValue;
	    if (currentUser && currentUser.token) {
	        return `Bearer ${currentUser.token}`
	    } else {
	        return '';
	    }
	}
	function axiosHeader () {
	    // return authorization header with jwt token
	    const currentUser = authenticationService.currentUserValue;
	    if (currentUser && currentUser.token) {
	        return  {
	          headers : {
	             Authorization: `Bearer ${currentUser.token}`,
	          }
	        }
	    } else {
	        return {}
	    }
	}

