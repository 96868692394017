import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import { authHeader } from '../../_helpers'
import { Notif , Loading } from '../../_components'
import axios from 'axios';
import moment from 'moment';

const { REACT_APP_API_URL :  API_URL} =process.env

class SaleModalRedux extends React.Component {
   constructor({props}) {
      super(props);
      this.state = {
        url : `${API_URL}/api/sale_modal`,
        loading : null,
      
    }
  }

  componentDidMount(){
       $('#items').DataTable( {
        ordering: true,
        searching: true,
        processing: true,
        serverSide:true,
        bFilter: true,
        bInfo:true,
        bLengthChange: true,
        bPaginate:true,
        ordering: true,
        scrollCollapse: true,
        scrollY: true,
        scrollX: true,
        "ajax": {
          "url": `${this.state.url}?idcustomer=${this.props.modal ? this.props.modal.customer : 0}`,
          "dataSrc" : 'data',
          "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',authHeader())
            }
          },
        columnDefs : [
            {
                'targets': [0],
                createdCell : (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<React.Fragment>
                    <button 
                      disabled={(this.props.modal.banned_options && this.props.modal.banned_options.find(e => e === rowData[0]) ) || rowData[5] === 0 ? true : false} 
                      className="btn btn-primary btn-sm" 
                      onClick={() => {
                        this.Pilih(
                              rowData[4],
                              rowData[8],
                              rowData[1],
                              rowData[5],
                              rowData[9],
                              rowData[10],
                              rowData[6],
                              rowData[11],
                              rowData[12],
                              rowData[13],
                              rowData[7],
                              rowData[0],
                              rowData[14], 
                              rowData[15],
                              rowData[16])
                      }}>Pilih</button>
                      </React.Fragment>,td);
                }
            },
             {
                'targets': [3],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment>{moment(rowData[3]).format("DD-MM-YYYY, h:mm:ss a")}</React.Fragment>,td);
                }
            },
            {
                'targets': [6],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><b>{new Intl.NumberFormat( 'ID' ).format(rowData[6])}</b></React.Fragment>,td);
                }
            },
            {
                'targets': [7],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><b>{new Intl.NumberFormat( 'ID' ).format(rowData[7])}</b></React.Fragment>,td);
                }
            },
            
        ]
     } );
    }

   Pilih(name, id, code, qty, id_unit, unit, price, disc_p, disc_t, tax_t, tot_price, id_pur_d, id_pur, type_item, id_item_package){
     const {onChange}= this.props.modal
     const options = {
        headers : {
           Authorization: authHeader(),
        }
      }

     this.setState({loading: true})
     onChange && axios.all([
        axios.get(`${API_URL}/api/get_sale_taxes?id=${id_pur_d}`, options),
     ])
     .then(axios.spread((taxes) => {
        this.setState({loading: false})
        onChange(name, id, code, qty, id_unit, unit, price, disc_p, disc_t, taxes.data, tax_t, tot_price, id_pur_d, id_pur, type_item, id_item_package)
     }))
     .catch(({response : { request : { response : error }} }) => {
        this.setState({loading: false})
        Notif('Opssss', error,'toast-bottom-right','4')
      });
   } 

   onClose = () =>{
    this.props.Modal(null)
   }

  render() {

    const backdropStyle = {
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };
  // console.log()
  return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >
         <Modal.Header>
        <Modal.Title>Cari Penjualan </Modal.Title>
        </Modal.Header>

        {this.state.loading ? <div><Loading/></div> :
          <Modal.Body>
                <table className="table table-striped" style={{whiteSpace: 'nowrap', width: '100%'}} id="items">
                  <thead>
                      <tr>
                          <th></th>
                          <th>Kode Penjualan</th>
                          <th>Cabang</th>
                          <th>Tanggal</th>
                          <th>Item</th>
                          <th>Qty</th>
                          <th>Harga Satuan</th>
                          <th>Harga Total</th>
                      </tr> 
                  </thead>
                </table>
          </Modal.Body>
        }
        <Modal.Footer>
            <Button variant="secondary" onClick={this.onClose}>Close</Button>
        </Modal.Footer>
        </Modal.Dialog>
      </div>
      </div>
    )
  }
}


export  { SaleModalRedux };




