import React from 'react'
import { Field, ErrorMessage } from 'formik';
import { CurrencyInput } from '../_components';

export class FieldMask extends React.Component{


render() {
  const {errors, touched, name, type, className, onChange=() => {}, placeholder, disabled, onClick=() =>{}  }=this.props
 return(
        <React.Fragment>
            {onChange &&
              <Field name={name}
                     render={({ field }) => (
                    <CurrencyInput
                      {...field}
                      onClick={onClick}
                      placeholder={placeholder? placeholder : ''}
                      type={type}
                      onChange={onChange}
                      disabled={disabled}
                      className={
                                 className ? className : 'form-control' + 
                                (errors && touched ? ' is-invalid' : '')
                              }
                    />
                  )} 
              />
            }

              <ErrorMessage 
                  name={name} 
                  component="div" style={{color:'red'}} 
                  className="invalid-feedback" />
        </React.Fragment>
    )
  }
}
