import { Component } from 'react';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css'

export const Notif= (title,conteks,position,type) => {
  toastr.options = {
          "closeButton": true,
          "debug": true,
          "newestOnTop": true,
          "progressBar": true,
          "positionClass": `${position}`,
          "preventDuplicates": true,
          "onclick": null,
          "showDuration": "300",
          "hideDuration": "1000",
          "timeOut": "5000",
          "extendedTimeOut": "1000",
          "showEasing": "swing",
          "hideEasing": "linear",
          "showMethod": "fadeIn",
          "hideMethod": "fadeOut"
        }
   if(type==='1'){toastr.success(`${title}`, `${conteks}`)}
   else if(type==='2'){toastr.info(`${title}`, `${conteks}`)}   	
   else if(type==='3'){toastr.warning(`${title}`, `${conteks}`)}   	
   else if(type==='4'){toastr.error(`${title}`, `${conteks}`)}   
	
  
}
