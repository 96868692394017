import React from 'react'
import $ from 'jquery';

export class RowDetail extends React.Component{
  constructor(props) {
        super(props);
        this.state = {
            row : false
        }
    }

 componentDidMount() {
      $(window).bind('load resize', () => {
         if ($(document).width() < 769) {
            this.setState({row: true})
         }else{
            this.setState({row: false})
         }
      });
  }

render() {
const { component } = this.props
if(component){
 return(
        component.map((e, key)=>{
          if(this.state.row){
            return(
                    <div className="row"> 
                    <label className="col-5"><b>{e.label}</b></label>
                    <div className="col-7">: &nbsp;{e.value}</div>
                    </div>
            )
          }else{
            return (
                    <div className="row">
                    <label className="col-lg-4"><b>{e.label}</b></label>
                    <div className="col-lg-8">: &nbsp;{e.value}</div>
                    </div>
              )
          }
        })
    )}else{
        return null
    }
  }

}

