import React from 'react'
import {PrivateRoute} from '../_components';
import { Link, Switch} from 'react-router-dom';
import $ from 'jquery';

export class LeftTab extends React.Component{


render() {
const {component, location: { pathname }, parentCallback } = this.props
if(component){
let parent=''
 return(
     <div className="row">
      <div className="col-lg-3">
        <div className="list-group"  role="tablist" aria-orientation="vertical">

            {
               
                component && component.map((k, key)=>{
                    if(k.name){
                        return(
                        <Link key={key} 
                              className={'list-group-item list-group-item-action '+ (pathname===k.link ? 'active': '')} 
                              to={`${k.link}`}>{k.name} &nbsp;&nbsp;
                              <span className={k.icon}></span>
                        </Link>
                        )
                     
                    }else{
                        return null
                    }

                })

            }
        </div>
      </div>
      <div className="col-lg-9">
        <div className="" style={{marginTop : ($(document).width() < 769) ? 20 : 0 }}>
                {
                    component && component.map((k, key)=>{
                        return(
                            <PrivateRoute exact={k.name ? true : false } path={`${k.path}`} parentCallback={parentCallback} component={k.component} />
                        )
                    })

                }
        </div>
      </div>
    </div>

    )}else{
        return null
    }
  }

}

