import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import $ from 'jquery'
import 'datatables.net'
import 'datatables.net-responsive'
import 'datatables.net-dt/css/jquery.dataTables.min.css'
import { authHeader } from '../../_helpers'
import axios from 'axios';
import moment from 'moment';

const { REACT_APP_API_URL :  API_URL} =process.env

class UmSupplierModalRedux extends React.Component {
  componentDidMount(){
       $('#items').DataTable( {
        ordering: true,
        searching: true,
        processing: true,
        serverSide:true,
        bFilter: true,
        bInfo:true,
        bLengthChange: true,
        bPaginate:true,
        ordering: true,
        scrollCollapse: true,
        scrollY: true,
        scrollX: true,
        "ajax": {
          "url": `${API_URL}/api/purchase_advance_modal${this.props.modal.query ? this.props.modal.query : ''}`,
          "dataSrc" : 'data',
          "beforeSend": function (xhr) {
              xhr.setRequestHeader('Authorization',authHeader())
            }
          },
        columnDefs : [
            {
                'targets': [0],
                createdCell : (td, cellData, rowData, row, col) => {
                    ReactDOM.render(<React.Fragment>
                    <button 
                      disabled={this.props.modal.banned_option && (this.props.modal.banned_option.find(e => e === rowData[0])) ? true : false} 
                      className="btn btn-primary btn-sm" 
                      onClick={() => {
                        this.Pilih(rowData[0],rowData[1], rowData[4],rowData[7],rowData[6])
                      }}>Pilih</button>
                      </React.Fragment>,td);
                }
            },{
                'targets': [3],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment>{moment(rowData[3]).format("DD-MM-YYYY, h:mm:ss a")}</React.Fragment>,td);
                }
            },{
                'targets': [4],
                createdCell : (td, cellData, rowData, row, col) => {
                   ReactDOM.render(<React.Fragment><b>{new Intl.NumberFormat( 'ID' ).format(rowData[4])}</b></React.Fragment>,td);
                }
            }
            
        ]
     } );
    }

   Pilih(id,nm, price, id_akun, nm_akun){
     const {onChange}= this.props.modal
     onChange && onChange(id,nm ? nm : '-', price, id_akun, nm_akun)
     this.onClose()
   } 

   onClose = () =>{
    this.props.Modal(null)
   }

  render() {

    const backdropStyle = {
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };

   
    return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            size="lg"
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >
         <Modal.Header>
        <Modal.Title>Cari UM Supplier</Modal.Title>
        </Modal.Header>

        <Modal.Body>
                <table className="table table-striped" style={{whiteSpace: 'nowrap', width: '100%'}} id="items">
                  <thead>
                      <tr>
                          <th></th>
                          <th>Kode</th>
                          <th>Cabang</th>
                          <th>Tgl</th>
                          <th>Saldo</th>
                          <th>Note</th>
                      </tr> 
                  </thead>
                </table>
        </Modal.Body>

        <Modal.Footer>
            <Button variant="secondary" onClick={this.onClose}>Close</Button>
        </Modal.Footer>
        </Modal.Dialog>
      </div>
      </div>
    );
  }
}


export  { UmSupplierModalRedux };




