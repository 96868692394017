import React from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ReactDOM from 'react-dom'
import axios from 'axios';
import { Notif, ButtonLoading} from '../../_components'
import { authHeader } from '../../_helpers'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {Progress} from 'reactstrap';

const { REACT_APP_API_URL :  API_URL} =process.env
class ImportModal extends React.Component {
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null,
        loaded : 0
      }
   
  }
 
 onChangeHandler= event => {
        this.setState({
          selectedFile: event,
          loaded: 0,
         })
 }
  
 checkMimeType=(event)=>{
   return new Promise((resolve, reject) => {
      let files = event.target.files 
      let err = ''
      let size = 100000 
     const types = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel']
      for(var x = 0; x<files.length; x++) {
        if (types.every(type => files[x].type !== type)) {
           err += `${files[x].type} is not a supported format, `;
         }

       if (files[x].size > size) {
           err += `${files[x].size} is too large, please pick a smaller file`;
       }
    };

     if (err !== '') { 
          reject(err) 
      }else{
          resolve(event.target.files[0])
      }
    })

}



  render() {
    if(!this.props.show) {
      return null;
    }

   const backdropStyle = {
      position: 'fixed', /* Stay in place */
      zIndex: 1, /* Sit on top */
      left: 0,
      top: 0,
      width: '100%', /* Full width */
      height: '100%', /* Full height */
      overflow: 'auto', /* Enable scroll if needed */
      backgroundColor: 'rgb(0,0,0)', /* Fallback color */
      backgroundColor: 'rgba(0,0,0,0.4)' /* Black w/ opacity */
    };

    const {onClose, title, id }= this.props
    return (
      <div className="modal-backdrop" style={backdropStyle}>
      <div className='animated fadeInRight'>
        <Modal.Dialog 
            aria-labelledby="example-modal-sizes-title-lg"
            style={{top : 25}}
         >         
            <Formik
             initialValues={{
                nmfile: '',
                }}

                validationSchema={Yup.object().shape({
                    nmfile: Yup.string().required('File is required'),
                })}

                onSubmit={( values, { setSubmitting }) => {
                    const data = new FormData() 
                    data.append('file', this.state.selectedFile)
                    const url = `${API_URL}/api/upload?id=${id}`;
                    axios.post(url, data , {
                      headers : {
                         Authorization: authHeader(),
                      },
                      onUploadProgress: ProgressEvent => {
                             this.setState({
                               loaded: (ProgressEvent.loaded / ProgressEvent.total*100),
                           })
                       },
                    }).then(res=>{
                      Notif('Success', 'Data berhasil di import','toast-bottom-right','1')
                      onClose(true)
                      setSubmitting(false)
                    }).catch(({response : { request : { response : error }} }) => {
                        setSubmitting(false)
                        Notif('Opssss', error,'toast-bottom-right','4')
                    });
                 
                }}
                                      
                render={({setStatus, errors, status, touched, isSubmitting, setFieldValue, setFieldError, setFieldTouched}) => {

                let err1=''
                if (errors.nmfile && touched.nmfile){
                  err1=errors.nmfile
                }else if (status){
                  err1=status
                }

                return(
                 <Form>
                  <Modal.Header>
                    <Modal.Title>Import {title}</Modal.Title>
                  </Modal.Header>

                      <Modal.Body>
                        <div className="">
                            <div className="row">
                              <div className="col-md-12">
                            
                              <div className="form-group files">
                                <label>Upload Your File </label>
                                 <input 
                                  name="nmfile" 
                                  type="file"
                                  onChange={(event) => {
                                    if(event.target.files[0]){
                                      const { name }= event.target.files[0]
                                      setFieldValue('nmfile', name) 
                                      this.checkMimeType(event).then( e => {
                                         setStatus();
                                         this.onChangeHandler(e)
                                      }).catch(err => {
                                        setStatus(err);
                                      })
                                    }else{
                                      setStatus();
                                      setFieldValue('nmfile', '') 
                                      setFieldTouched('nmfile', true)
                                    }

                                  }}
                                  className={'form-control' + (err1 ? ' is-invalid' : '')} />
                              </div>
                               <label style={{color: 'red', fontSize : 11}}>{(err1 ? err1 : '' )}</label>
                               <Progress max="100" color="primary" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
                              </div>
                            </div>
                          </div>
                      </Modal.Body>

                     <Modal.Footer>
                          <ButtonLoading
                            loading={isSubmitting}
                            disabled={err1 ? true : false}
                          >Simpan
                        </ButtonLoading>
                        <Button variant="secondary" onClick={onClose}>Close</Button>
                     </Modal.Footer>
                  </Form>
                  )}}
              />
                      
        </Modal.Dialog>
      </div>
      </div>
    );
  }
}


export  { ImportModal };




